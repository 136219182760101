import { Fragment, useRef, ReactElement } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { ExclamationIcon } from "@heroicons/react/outline";

export const THEME: {
  [key: string]: { default: string; hover: string; ring: string };
} = {
  DANGOR: {
    default: "bg-red-600",
    hover: "hover:bg-red-700",
    ring: "focus:ring-red-500",
  },
  PRIMARY: {
    default: "bg-blue-600",
    hover: "hover:bg-blue-700",
    ring: "focus:ring-blue-500",
  },
  SUCCESS: {
    default: "bg-green-600",
    hover: "hover:bg-green-700",
    ring: "focus:ring-green-500",
  },
  WARNING: {
    default: "bg-yellow-600",
    hover: "hover:bg-yellow-700",
    ring: "focus:ring-yellow-500",
  },
};

export default function Modal(props: {
  open: boolean;
  handleClose: any;
  title?: string;
  content: ReactElement;
  submit: { label: string; theme: typeof THEME.key; action: any };
  cancel: any;
}) {
  const { open, handleClose } = props;

  // const [open, setOpen] = useState(props.open);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => handleClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div> */}
                  <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {props.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        {props.content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={` w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${props.submit.theme.default} text-base font-medium text-white ${props.submit.theme.hover} focus:outline-none focus:ring-2 focus:ring-offset-2 ${props.submit.theme.ring} sm:ml-3 sm:w-auto sm:text-sm`}
                  onClick={() => {
                    props.submit.action();
                    handleClose();
                  }}
                >
                  {props.submit.label}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleClose()}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
